<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
          <div class="bannerDiv" v-if="$route.name=='ResourceDetail'">
            <el-carousel  :interval="5000" arrow="always" :height="autoHeight">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img :style="{cursor:item.link?'pointer':'initial'}"  style="width:100%;height:auto;" @click="clickBanner(item.link)" :src="item.imgUrl" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>

          <img v-else style="width:100%" src="@/assets/image/services/download_banner.jpeg" alt="">
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/services/sale_support.html">服务支持</a></el-breadcrumb-item>
            <el-breadcrumb-item>资料下载</el-breadcrumb-item>
            <el-breadcrumb-item v-if="activeMenu&&activeMenu.asideName">{{activeMenu.asideName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.name=='ResourceDetail'">{{$route.params.name}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" >
          <el-row :gutter="0" style="width:100%">
            <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="4" style="padding:0px;">
                <div :style="{width:isMoPhone?'100%':'93%', marginBottom:isMoPhone?'15px':'0px'}" >
                    <!-- 热门搜索 -->
                    <div class="hotSearch">
                        <div class="hotTitle" >热门搜索</div>
                        <div class="hotContent">
                            <div class="hotText" v-for="(item,index) in hotTextArr" :key="index" @click="clickHotText(item)">{{item.title}}</div>
                        </div>
                    </div>
                    <side-bar ref="sideBar" v-loading="loading"  :asideList="asideList" :activeMenu="activeMenu" @currentAside = currentAside>
                    </side-bar>
                    <img class="videoImg" @click="clickVideoImg('http://www.wch.cn/videos/BluetoothMesh.html')"  style="margin-top:10px" src="@/assets/image/services/video1.jpeg" alt="">
                    <img class="videoImg" @click="clickVideoImg('https://www.wch.cn/videos/USB-to-serial-port-chip-driver-installation-on-Windows.html')" src="@/assets/image/services/video2.jpeg" alt="">
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="18" :lg="19" :xl="20" style="padding:30px;background:#fff;">
                <keep-alive >
                    <router-view :key="viewKey"/>
                </keep-alive>
            </el-col>
          </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '@/pages/topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import sideBar from '@/pages/components/sidebar'
export default {
  name: "downloadsCategory",
  components:{
    topBar,
    footerPart,
    sideBar,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        autoHeight:'',
        activeMenu:{},
        bannerList:[
          {imgUrl:require('@/assets/image/services/banner1.png'), link:'https://www.wch.cn/products/CH585.html'},
          {imgUrl:require('@/assets/image/services/banner2.png'), link:'https://www.wch.cn/products/CH32V317.html'},
          {imgUrl:require('@/assets/image/services/banner3.png'), link:'https://www.wch.cn/products/CH634.html'},
        ],
        asideList:[
          {
            asideName:'',
            childList:[
              {key:'1', asideName:'产品手册', path:'/downloads/category/27.html'},
              {key:'2', asideName:'开发资源', path:'/downloads/category/28.html'},
              {key:'3', asideName:'驱动程序', path:'/downloads/category/67.html'},
              {key:'4', asideName:'工具软件', path:'/downloads/category/30.html'},
              {key:'5', asideName:'其他', path:'/downloads/category/29.html'},
              {key:'6', asideName:'视频资料', path:'/videos.html'},
              {key:'7', asideName:'联系我们', path:'/contact_us.html'},
            ]
          }
        ],
        hotTextArr:[
            {title:"BLE单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"RISC-V", link:"https://www.wch.cn/search.html?q=RISC-V&t=all"},
            {title:"USB单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"MCU", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"USB PD", link:"https://www.wch.cn/search.html?q=USB+PD&t=all"},
            {title:"以太网", link:"https://www.wch.cn/search.html?q=以太网&t=all"},
            {title:"USB转串口", link:"https://www.wch.cn/search.html?q=USB转串口&t=all"},
            {title:"Ethernet", link:"https://www.wch.cn/search.html?q=Ethernet&t=all"},
            {title:"serial", link:"https://www.wch.cn/search.html?q=serial&t=all"},
        ],
        loading:false,
        content:'',
    };
  },
  computed: {
    viewKey() {
       return this.$route.path
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('download的mounted--')
    if (this.windowWidth < 992) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }
    if(this.$route.name=='DownloadsCategory'){
      this.activeMenu = {key:'1', asideName:'产品手册', path:'/downloads/category/27.html'}
    }else{
      for(let item of this.asideList[0].childList){
        if(item.path == this.$route.path){
          this.activeMenu = item
          break
        }
      }
    }
    this.getAutoHeight()
    if(this.$route.name!='ResourceDetail'){//非资料下载详情页
      this.currentAside(this.activeMenu)
    }
   
    
    this.$bus.$on("left_categoryId", (categoryId) => {//下载资料详情页
      if(categoryId==27){
        this.activeMenu = {key:'1', asideName:'产品手册', path:'/downloads/category/27.html'}
      }else if(categoryId==28){
        this.activeMenu = {key:'2', asideName:'开发资源', path:'/downloads/category/28.html'}
      }else if(categoryId==67){
        this.activeMenu = {key:'3', asideName:'驱动程序', path:'/downloads/category/67.html'}
      }else if(categoryId==30){
        this.activeMenu = {key:'4', asideName:'工具软件', path:'/downloads/category/30.html'}
      }else if(categoryId==29){
        this.activeMenu = {key:'5', asideName:'其他', path:'/downloads/category/29.html'}
      }
    })
    
    
    
  },
  activated(){
    console.log('activated')
    if(this.$route.name=='DownloadsCategory'){
      this.activeMenu = {key:'1', asideName:'产品手册', path:'/downloads/category/27.html'}
    }else{
      for(let item of this.asideList[0].childList){
        if(item.path == this.$route.path){
          this.activeMenu = item
          break
        }
      }
    }
    this.getAutoHeight()
    this.$nextTick(()=>{
      this.currentAside(this.activeMenu)
    })
  },
  beforeDestroy() {
    this.$bus.$off("left_categoryId");
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
      this.getAutoHeight()
    },
    getAutoHeight(){
        let _w = document.documentElement.clientWidth || document.body.clientWidth; 
        this.autoHeight = _w * 300 / 1920 + 'px'; //banner图在1920像素设计稿下是300px高度
    },
    //点击侧边栏
    currentAside(item){
      console.log('点击的menu',item)
      this.activeMenu = item
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.$router.push({ path: item.path,})
    },
    //点击banner
    clickBanner(link){
      if(link){
        window.open(link)
      }
    },
    //点击热门搜索
    clickHotText(t){
      window.location.href = t.link
    },
    //点击视频图片
    clickVideoImg(link){
      window.open(link)
    },
    
  }
};
</script>
<style scoped lang="less">
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bannerDiv{
    // .el-carousel__container{
    //   height:300px
    // }
    // .bannerImg{
    //   width:100%; 
    //   height:300px
    // }
  }
 .bread{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 4px;
    .hotSearch{
      min-height: 30px;
      background-color: #ffffff;
      margin-bottom: 10px;
      box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
      padding: 10px;
      .hotTitle{
        font-size: 16px;
        line-height: 20px;
        margin: 10px 0px ;
        display: block;
        color: #000000;
        border-left: 2px transparent solid;
        text-align: left;
      }
      .hotContent{
        display: flex;
        flex-wrap: wrap;
        .hotText{
          padding-left: 8px;
          width: 50%;
          box-sizing: border-box;
          background-color: #fff;
          color: #1ea9fb;
          cursor: pointer;
          text-align: left;
          &:hover{
              text-decoration: underline;
          }
        }
      }
    }
    .videoImg{
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
    }
 }
 
@media screen and (max-width: 1400px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:250px
    // }
    // .bannerDiv .bannerImg{
    //     height:250px
    // }
}
@media screen and (max-width: 1100px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:220px
    // }
    // .bannerDiv .bannerImg{
    //     height:220px
    // }
}
@media screen and (max-width: 800px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:180px
    // }
    // .bannerDiv .bannerImg{
    //     height:180px
    // }
}
@media screen and (max-width: 500px) {
    // .bannerDiv /deep/.el-carousel__container{
    //    height:140px
    // }
    // .bannerDiv .bannerImg{
    //     height:140px
    // }
}
</style>